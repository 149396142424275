import { IconProps } from './IconProps';

export default function IconArrival(props: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
      viewBox="0 0 42 42"
      {...props}
    >
      <path d="m16.5 2.487 16.585 17.477a1.5 1.5 0 0 1 0 2.072L16.5 39.513l-2.17-2.072L28.495 22.5H0v-3h28.495L14.33 4.558l2.17-2.07ZM42 0h-3v42h3V0Z" />
    </svg>
  );
}
